.recommendedNodeName {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
}

.recommendedNodeName:hover {
    font-style: italic;
}

.fadeOut {
    transition-property: 'opacity';
    transition-duration: 0.3s;
    opacity: 0;
}

@keyframes rotate90 {
    from {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(90deg);
    }
    to {
        transform: rotate(90deg);
    }
}

@keyframes rotate360 {
    0% {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }

}

.rotateImageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.rotateImage:hover {
    animation-duration: 1.5s;
    animation-name: rotate90;
    animation-iteration-count: infinite;

}

.rotateFast {
    animation-duration: 0.5s;
    animation-name: rotate360;
}