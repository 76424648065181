.headerLink {
    margin-right: 20px;
}

.headerLine {
    margin-top: 1em;
    border-top: 1px solid currentColor;
    width: 90%;
}

.selected {
    /* background-color: orange; */
    color: #0144b4;
}