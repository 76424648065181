.dropdown {
    background-color: white;
    padding: 0;
}

.dropdown .selected {
        padding-left: 12px;
        padding-top: 3px;
        padding-bottom: 3px;
        width: 100%;
        background-color: lightblue;
    }
.dropdown .notSelected {
    padding-left: 12px;
        padding-top: 3px;
        padding-bottom: 3px;
        width: 100%;
        background-color: white;
}


.dropdown .selected:hover {
    background-color: lightgray;
    cursor: pointer
}
.dropdown .notSelected:hover {
    background-color: lightgray;
    cursor: pointer
}

.disabled
{
    cursor: default;
    opacity: 0.65;
}