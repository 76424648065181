@use 'styles/colors' as colors; 

.reportsContainer {
    display: flex; 
    width: 100%; 
    height: 100%;
    flex-direction: row;

    > .leftColumn {
        flex: 0 0 auto;
        height: 100%; 
        margin-right: 20px;
    }    

    > .rightColumn {
        flex: 1 0 auto;
        height: 100%; 
    }
}

.filterContainer {
    width: 300px; 
}

.eventFlowContainer {
    color: #333; 
    padding: 5px;
    border-radius: 5px; 
    width: 100%;     
}

.notificationContainer {
        background: white; 
}
 
.reportTypeSelectorContainer {
    width: 100%; 
}

.reportContainer {
    width: 100%;
}

.aboveAverage {
    background-color: #b3ea4b;
}

.average {
}

.belowAverage {
    background-color: red;
}
