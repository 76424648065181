@use 'styles/colors' as colors; 

.assesmentFilter {
    font-size: smaller;
    display: flex;
    > div {
        flex: 1 1 auto;
    }
}

.taskActivity {
    font-size: smaller;
    padding: 5px 0;
    border-bottom: colors.$orangeish-contrast-color solid 1px;
    line-height: 1.5em;

    .studentName {
        font-size: smaller;
        font-weight: bold;
    }

    .shownTime,
    .taskHeadline {
        font-style: italic;
    }

    .label {
        font-style: italic;
    }

    .value {
        color: colors.$secondary-emphasized-text-color;
    }

    .question {
        font-weight: 700;
    }

    .givenPart {
        color: colors.$secondary-emphasized-text-color;
    }

    .changeablePart {
        color: colors.$emphasized-text-color;
        border-bottom: 1px dotted colors.$emphasized-text-color;
    }

    .answer {
        font-weight: bold;

        .answerItem {
            margin-left: 5px;
        }
    }

    .alternative {
        &.correct {
            color: colors.$greenish-text-color;
        }

        &.incorrect {
            color: colors.$reddish-text-color;
        }
    }
}
