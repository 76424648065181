
$plain-text-color: #404040;
$background-color: #f6d635;
$footer-color: #f67535;

$orangeish-contrast-color: #c06010; /* NB: Not suitable for text on the yellow background */

$greenish-text-color: #140;
$reddish-text-color: #720;
$brownish-text-color: #530;
$greyish-text-color: #444030;

$emphasized-text-color: $brownish-text-color;
$secondary-emphasized-text-color: $greyish-text-color; 

$primary: #35b7f6;
$secondary: #d1edfa;

