.taskSelected {
    border: 2px solid blue;
    border-radius: 10px;
}
:focus {
    // border: 2px solid red;
    // border-radius: 10px;
}

.taskNotSelected {
    border: none;
}

table, th, td {
    border: 1px solid;
  }