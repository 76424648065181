.flexContainer {
    display: flex;
}

.flexChild {
    flex: 1;
}

.flexChild:first-child {
    margin-right: 20px;
}

.subareaList {
    list-style: none;
    border-left: 1px solid;
    margin: 1em 0;
    /* text-decoration-line: underline; */
    cursor: pointer;
}

.selected {
    color: green;
    background-color: purple;
}

.searchResult {
    text-decoration: underline;
    cursor: pointer;
} 

.searchResult:hover {
    color: #2a92c5;
}

.subarea_name:hover {
  cursor: pointer;
  font-weight: bold;
  color: black;
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
  .arrow:hover {
    width: 110%;
    border-width: 0 5px 5px 0;
  }
  
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  
  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .tooltip {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  
    /* Tooltip text */
    .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        top: 100%;
        
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
        &.tooltiptext_right {
            left: -5%;
        }
        &.tooltiptext_left {
            left: 5%;
        }
    }
}
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
