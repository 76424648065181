

.boxcontainer {
    // border: 1px solid black;
}

.notSelected {
    // background-color: grey;
    color: grey;
}

.selected {
    // background-color: #f67535;
    color: #474747
}



