.container {
    width: 100%;

    select {
        width: 100%;
    }

    .dropdown {
        position: absolute;
        top: 0; 
        left: 0; 
        right: 0;
        max-height: 30%;
        background: #ffffff40;

        &.collapsed {
            display: none; 
        }

        &.expanded {
            display: block;
        }
    }
}
