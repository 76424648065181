.feedback {
    .feedbackHeader{
        background: grayscale($color: #888);
        border: 1px solid orange;
        padding: 0px 20px;
    }
    .feedbackBox {
        background: grayscale($color: #bbb);
        border: 1px solid orange;
        padding: 0px 20px;
    }
    .feedbackInput {
        background-color: white;
        border: 1px solid orange;
    }
}