

@import 'styles/colors.scss';
@import "bootstrap";

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #474747;
  background-color: #f6d635;
  margin-bottom: 5em; /* make room for the ::after */
}

body::after {
/*html:: after {*/
  content: '';
  display: block;
  position: fixed; 
  left: 0;
  right: 0;
  bottom: 0;
  height: 5em;
  background-color: #f67535;
}

a {
  color: inherit;
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

