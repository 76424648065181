
.header {
    font-family: 'Architects Daughter, cursive'
}

.singleCharAnswer {
    /* background-color: white; */
    /* box-shadow: 0 0 2px 1px white inset; */
    animation-duration: 3s;
    animation-name: pulseShadow;
    animation-iteration-count: infinite;
}

.singleCharAnswer:empty:before {
    content: '_';
}

.singleCharAnswer:empty:not(:focus):before {
    animation-duration: 1s;
    animation-name: blinkUnderscore;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
.singleCharAnswer:focus {
    outline: none;
    background-color: white;
}

@keyframes blinkUnderscore {
    from {
        opacity: 100%;
    }

    50% {
        opacity: 100%;
    }

    to {
        opacity: 0%;
    }
}

@keyframes pulseShadow {
    from {
        box-shadow: 0 0 2px 1px white inset;
    }

    to {
        box-shadow: none;
    }
}
